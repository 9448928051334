export const changePoint = 300
export const carouselSettings = {
  arrows: true,
  dots: false,
  draggable: true,
  infinite: true,
  variableWidth: true,
  centerpadding: "90px",
  swipeToSlide: true,
  focusOnSelect: true, 
}
export const carouselLinksSettings = {
  ...carouselSettings,
  infinite: false,
}
export const carouselSettingsDots = {
  arrows: false,
  draggable: true,
  infinite: true,
  variableWidth: true,
}
export const carouselSettingsWithTitle = {
  arrows: false,
  dots: false,
  draggable: true,
  infinite: true,
}
export const carouselSettingsTitle = {
  ...carouselSettingsWithTitle,
  variableWidth: false,
  swipeToSlide: true,
  focusOnSelect: true,
  // infinite:false,
}
export const carouselSettingsSlider = {
  ...carouselSettingsWithTitle,
  swipeToSlide: false,
  slidesToShow: 1,
}

export type ContentProps = Maybe<
  | NewsFragment
  | HomeFragment
  | CultureFragment
  | ServicePackagesOffersFragment
  | PrivacyPolicyFragment
  | EulaFragment
  | AboutFragment
  | SitemapFragment
  | CookieFragment
  | ContactUsFragment
  | FaqFragment
  | WhyXcelProsFragment
  | PageShortFragment
  | IndustryFragment
  | ProductsFragment
  | ServicesFragment
>

export const isTabletValue = { maxWidth: 992 }
export const isMobileValue = { maxWidth: 576 }

export const widgetCode = import.meta.env.VITE_WIDGET_CODE
export const markerId = import.meta.env.VITE_WEBSITE_MARKER_ID

export const valueAllTypes = "All"
export const homePageLink = "/"

export const requestSuccessMessage = "Request sent successfully"
export const requestErrorMessage = "Something went wrong, please try again later"
export const emailSuccessMessage = "Email sent successfully"
export const pathSearch = "search"
export const pathCareers = "careers"
export const pathProducts = "products"
export const pathServices = "consulting-services"
export const pathScheduleMeeting = "schedule-meeting"
export const pathBlog = "blog"
export const pathBrochure = "brochures"
export const pathChallenges = "challenges"
export const pathCustomerJourneys = "customer-journeys"
export const pathSlides = "slides"
export const pathWhitepaper = "whitepapers"
export const pathIndustries = "industries"
export const pathTag = "tag"
export const pathCategory = "category"
export const indexesToSearchFrom = [
  "blog-post",
  "brochure",
  "challenge",
  "customer-journey",
  "product",
  "service",
  "slide",
  "whitepaper",
]
export const indexesForInsightsSearch = [
  "blog-post",
  "brochure",
  "challenge",
  "customer-journey",
  "slide",
  "whitepaper",
]
