import { Layout as BaseLayout } from "antd";
import { FC, Suspense, useEffect, useState, Fragment } from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  usePageQuery,
  useTrendingBlogPostCardQuery,
} from "../../graphql";
import { ContentProps } from "../constants";
import { useGeneralContext } from "../context";
import { PopUpGated } from "../section/popUpGated";
import { Loader } from "../ui/Loader";
import { filterByPathname } from "../utils";
import GlobalFooter from "../Global/GlobalFooter";
import GlobalHeader from "../Global/GlobalHeader";

const Layout: FC = () => {
  const { pathname } = useLocation();
  const [data, setData] = useState<ContentProps | null>(null);
  const { data: dataPage, loading } = usePageQuery();
  const { setLoading, setTrendingBlogPost, contextHolder, Pageloading } = useGeneralContext();
  const { data: dataTrendingBlogPost, loading: loadingTrendingBlogPost } = useTrendingBlogPostCardQuery();
  
  useEffect(() => {
    window.scrollTo(0, 0);
    if (pathname && dataPage?.page?.data?.attributes?.content) {
      setData(filterByPathname(pathname, dataPage.page.data.attributes.content as ContentProps[]));
    } else {
      setData(null);
    }
  }, [pathname, dataPage]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    if (!loadingTrendingBlogPost && dataTrendingBlogPost?.blogPosts?.data) {
      const formattedPosts = dataTrendingBlogPost.blogPosts.data.map(elem => ({
        isOpen: false,
        ...elem?.attributes
      }));
      setTrendingBlogPost(formattedPosts);
    }
  }, [loadingTrendingBlogPost, dataTrendingBlogPost, setTrendingBlogPost]);

  useEffect(() => {
    let inactiveTime: number | null = null;
    const INACTIVE_THRESHOLD = 30 * 60 * 1000;

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        inactiveTime = Date.now();
      } else if (document.visibilityState === 'visible' && inactiveTime) {
        const timeDiff = Date.now() - inactiveTime;
        if (timeDiff >= INACTIVE_THRESHOLD) {
          window.location.reload();
        }
        inactiveTime = null;
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  if (Pageloading) {
    return (
      <div className={"loading-screen"}>
        <Loader />
      </div>
    );
  }

  return (
    <Fragment>
      {!Pageloading && (
        <Suspense fallback={<Loader />}>
          <BaseLayout className={"layout"}>
            <GlobalHeader />
            {contextHolder}
            <PopUpGated />
            <Suspense fallback={<Loader />}>
              <div className={"wrapperMainContent"}>
                <Outlet context={data} />
              </div>
              <GlobalFooter />
            </Suspense>
          </BaseLayout>
        </Suspense>
      )}
    </Fragment>
  );
};

export { Layout };
