import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { logoProps } from "./GlobalTypes";
import { SvgIcon } from "../ui/SvgIcon";
import { useMediaQuery } from "react-responsive";

const HeaderLogo: React.FC<logoProps> = ({ logo }) => {
    const location = useLocation();
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1025px)' });

    if (!logo || !logo.lightLogo || !logo.lightLogo.data || !logo.lightLogo.data.attributes) {
        return null;
    }
    const { url: lightLogoUrl } = logo.lightLogo.data.attributes;
    const { url: darkLogoUrl } = logo.darkLogo.data.attributes;

    const handleLogoClick = (e: React.MouseEvent) => {
        if (location.pathname === "/") {
            e.preventDefault();
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    return (
        <NavLink to={"/"} onClick={handleLogoClick} aria-label={"Home"}>
            {isDesktopOrLaptop ? (  
                <img src={lightLogoUrl} alt={logo.Title} />
            ) : (
                <img src={darkLogoUrl} alt={logo.Title} />
            )}
        </NavLink>
    );
};

export default React.memo(HeaderLogo);